export const SET_ASKED_QUESTION = 'set_asked_question';
export const SET_MARK = 'set_mark';
export const SET_CORRECT = 'set_correct';
export const SET_MODE = 'set_mode';
export const SET_IS_LOG_IN = 'set_is_log_in';
export const SET_USERNAME = 'set_username';
export const SET_COUNT = 'set_count';
export const SET_SCHOOL_NAME = 'set_school_name';
export const SET_CLIENT_NAME = 'set_client_name';
export const SET_PROVINCE = 'set_province';
export const SET_ANSWERED = 'set_answered';
