import { useState, useRef, useEffect } from 'react';
import classNames from 'classnames/bind';
import { Link } from 'react-router-dom';
import { images } from '../../assets';
import Header from '../../Component/Layout/Header';
import config from '../../config';
import { getCookie, request } from '../../warehouse';
import styles from './Home.module.scss';

const cx = classNames.bind(styles);

function HomePage() {

    const [kahootGame, setKahootGame] = useState(false);
    const [kahootTime, setKahootTime] = useState(0);
    const [kahootLink, setKahootLink] = useState("test");
    const [countDownTime, setCountDownTime] = useState(["-1", "00", "00"]);
    const [kahootData, setKahootObject] = useState({});
    const [kahootReUpdate, setReUpdate] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => {
            const countDown = kahootTime - new Date().getTime();
            // calculate time left
            var days = Math.floor(countDown / (1000 * 60 * 60 * 24));
            var hours = Math.floor(
            (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
            ) + days * 24;
            var minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = Math.floor((countDown % (1000 * 60)) / 1000);

            if(hours <= 0) {
                hours = 0;
            }
            if(minutes <= 0) {
                minutes = 0;
            }
            if(seconds <= 0) {
                seconds = 0;
            }

            if(hours <= 9) {
                hours = "0" + hours;
            }
            if(minutes <= 9) {
                minutes = "0" + minutes;
            }
            if(seconds <= 9) {
                seconds = "0" + seconds;
            }

           

            if(hours == "00" && minutes == "00" && seconds == "00") {
                clearInterval(interval);
                setReUpdate(true);
            }

            
        
            setCountDownTime([hours,minutes,seconds])
            

            

        }, 1000);
    
        return () => clearInterval(interval);
    }, [kahootTime]);


    useEffect(() => {
        request
        .get('https://aolang.edu.vn/generalAPI/kahoot/getKahootGame.php', {

        })
        .then((data) => {
            if(data) {
                setKahootGame(data.kahootGameEnabled)
                setKahootTime(data.kahootTimestamp)
                setKahootLink(data.kahootGameLink)
                setKahootObject(data)
            }
        })
    }, [kahootReUpdate]);

    return (
        
        <div>
            <Header isLogin={getCookie().islogin} />
            <div className={cx('wrapper', 'second-wrapper')}>
                {kahootGame === false || kahootGame == true && parseInt(countDownTime[0]) == -1 || kahootGame == true && parseInt(countDownTime[0]) > 71 ? <img className={cx('logo-opacity')} src={images.aolang} alt="" /> : ""}
                {kahootGame === true && parseInt(countDownTime[0]) <= 71 && parseInt(countDownTime[0]) != -1  && 
                
                <div className={cx('kahoot-countdown-wrapper')}>
                    <img className={cx('logo-opacity')} src={images.aolangBlank} alt="" />
                    <p className={cx('kahoot-countdown-text-2')}>
                        {kahootLink !== "" ? "" : kahootData.kahootCountdownTitle}
                        
                    </p>
                    {
                        kahootLink === "" && 
                        <h2 className={cx('kahoot-countdown-text')}>
                            {countDownTime[0] + ":" + countDownTime[1] + ":" + countDownTime[2]}
                        </h2>
                    }
                    {
                        kahootLink !== "" && 
                        <a href={kahootLink}
                        target="_blank"
                        rel="noopener noreferrer"  className={cx('kahoot-countdown-text-4')}>
                            {kahootData.kahootCTA}
                        </a>
                    }
                    
                    {
                        kahootLink !== "" ? 
                        <a 
                        href={kahootLink}
                        target="_blank"
                        rel="noopener noreferrer" 
                        className={cx('kahoot-countdown-text-3')}>
                            {kahootData.kahootOpeningDes}
                        </a> :
                        <p className={cx('kahoot-countdown-text-3')}>
                            {kahootData.kahootDescription}
                        </p>
                    }
                    
                </div>
                }
                <div className={cx('game-instruction')}>
                    <div className={cx('logo-block-wrapper')}>
                        <div className={cx('logo-wrapper')}>
                            <Link to={config.routes.game + '?mode=marathon'}>
                                <img className={cx('logo-game')} src={images.marathon} alt="" />
                            </Link>
                            <Link to={config.routes.game + '?mode=marathon'} className={cx('logo-link')}>
                                Marathon
                            </Link>
                            <p className={cx('game-description')}>Chạy đua với 60s và tối đa 30 câu hỏi</p>
                        </div>
                        <div className={cx('logo-wrapper', 'left')}>
                            <Link to={config.routes.game + '?mode=hurdling'}>
                                <img className={cx('logo-game')} src={images.hurdling} alt="" />
                            </Link>
                            <Link to={config.routes.game + '?mode=hurdling'} className={cx('logo-link')}>
                                Hurdling
                            </Link>
                            <p className={cx('game-description')}>Trả lời câu hỏi với độ khó tăng dần</p>
                        </div>
                        <div className={cx('logo-wrapper', 'left')}>
                            <Link to={config.routes.game + '?mode=calculaphobia'}>
                                <img className={cx('logo-game')} src={images.calculaphobia} alt="" />
                            </Link>
                            <Link to={config.routes.game + '?mode=calculaphobia'} className={cx('logo-link')}>
                                Calculaphobia
                            </Link>
                            <p className={cx('game-description')}>Luyện tập tính nhanh, chính xác</p>
                        </div>
                    </div>
                    <div className={cx('logo-block-wrapper')}>
                        <div className={cx('logo-wrapper')}>
                            <Link to={config.routes.game + '?mode=deciphering'}>
                                <img className={cx('logo-game')} src={images.deciphering} alt="" />
                            </Link>
                            <Link className={cx('logo-link')} to={config.routes.game + '?mode=deciphering'}>
                                Deciphering
                            </Link>
                            <p className={cx('game-description')}>Giải mã mật thư, tìm ra thông điệp</p>
                        </div>
                        <div className={cx('logo-wrapper', 'left')}>
                            <Link to={config.routes.game + '?mode=vocab'}>
                                <img className={cx('logo-game')} src={images.vocab} alt="" />
                            </Link>
                            <Link to={config.routes.game + '?mode=vocab'} className={cx('logo-link')}>
                                Vocaboostery
                            </Link>
                            <p className={cx('game-description')}>"Boost" vốn từ tiếng Anh của bạn</p>
                        </div>
                    </div>
                </div>
            </div>
            <footer className={cx('third-wrapper')}>
                <p className={cx('slogan')}>
                    <span className={cx('slogan-first-letter')}>A</span>ttaining{' '}
                    <span className={cx('slogan-first-letter')}>O</span>pportunities,
                    <br />
                    <span className={cx('slogan-first-letter')}>L</span>earning{' '}
                    <span className={cx('slogan-first-letter')}>A</span>nd{' '}
                    <span className={cx('slogan-first-letter')}>N</span>onstop{' '}
                    <span className={cx('slogan-first-letter')}>G</span>rowing.
                </p>
                <div className={cx('policy')}>
                    <div>
                        <a href={config.routes.csqdt} className={cx('link')} target="_blank">
                            Chính sách quyền riêng tư
                        </a>
                        <a href={config.routes.dkdv} className={cx('second-policy', 'link')} target="_blank">
                            Điều khoản dịch vụ
                        </a>
                    </div>
                    <div>
                        <p>
                            <Link to={config.routes.contact} className={cx('link')}>
                                Liên hệ với chúng tôi
                            </Link>
                        </p>
                    </div>
                </div>
            </footer>
        </div>
    );
}

export default HomePage;
