import { useState, useRef, useEffect } from 'react';
import Popup from 'reactjs-popup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullhorn, faImage, faCircleInfo, faHeartCrack, faSearch, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames/bind';
import styles from './LovedQuestion.module.scss';
import { getCookie, useDebounce, request } from '../../../warehouse';
import CustomizeAudio from '../../../Component/CustomizeAudio';

const cx = classNames.bind(styles);

function LovedQuestion() {
    const [questions, setQuestions] = useState([]);
    const [index, setIndex] = useState(0);
    const [reload, setReload] = useState(0);
    const [search, setSearch] = useState('');
    const searchDebounced = useDebounce(search, 700);

    const cookies = useRef(getCookie());

    useEffect(() => {
        request
            .get('/favouriteques/getFavouriteQues.php', {
                params: {
                    id: getCookie().dxnlcm,
                    keyword: searchDebounced
                },
            })
            .then((res) => {
                if (!!res) {
                    setIndex(0);
                    setQuestions(res);
                }
            });
    }, [reload, searchDebounced]);

    const deleteFavouriteQues = (index) => {
        console.log(index)
        const payload = new FormData();
        payload.append('questionId', questions[index].id);
        payload.append('userId', cookies.current.dxnlcm);
        request.post('/favouriteques/deleteFavouriteQues.php', payload).then((res) => {
            if (!!res) {
                setIndex(0);
                setReload(reload + 1);
            }
        });
    };

    return (
        <div className={cx('wrapper')}>
            
            <div className={cx('header-wrapper')}>

            <div className={cx('field', 'login-field')}>
                    <div className={cx('search-bar')}>
                        <FontAwesomeIcon icon={faSearch} />
                        <p>
                        <input
                            className={cx('field-input')}
                            placeholder='Tìm kiếm...'
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                        />
                        </p>
                    </div>
                        
            </div>

            {questions.length > 0 && (
                <h4 className={cx('header')}>Câu hỏi số {index + 1}/{questions.length}</h4>
            )
            }

            {questions.length > 0 && (
                <div>
                <button className={cx('btn')} onClick={() => setIndex(0)}>
                    {'<<'}
                </button>
                <button
                    className={cx('btn')}
                    onClick={() => {
                        if (index >= 1) setIndex(index - 1);
                        else setIndex(0);
                    }}
                >
                    {'<'}
                </button>
                <input
                    className={cx('input-btn')}
                    value={index + 1}
                    onChange={(e) => {
                        if (e.target.value.length === 0) setIndex(0);
                        else {
                            let temp = Number.parseInt(e.target.value);
                            if (temp <= 1) setIndex(0);
                            else if (temp >= questions.length) setIndex(questions.length - 1);
                            else setIndex(temp - 1);
                        }
                    }}
                />
                <button
                    className={cx('btn')}
                    onClick={() => {
                        if (index <= questions.length - 2) setIndex(index + 1);
                        else setIndex(questions.length - 1);
                    }}
                >
                    {'>'}
                </button>
                <button className={cx('btn')} onClick={() => setIndex(questions.length - 1)}>
                    {'>>'}
                </button>
            </div>
            )}

            </div>
            {
                questions.length == 0 && (
                    <div className={cx('content-wrapper')}>
                        <div className={cx('favourite-noti')}>
                            {searchDebounced === "" ? <h2><FontAwesomeIcon icon={faTriangleExclamation} />  Chưa có câu hỏi ưa thích</h2> : <h2>
                            <FontAwesomeIcon icon={faTriangleExclamation} />  Không tìm thấy câu hỏi</h2>}
                            {searchDebounced === "" ? <p>Rảnh thì hãy ghé chơi nhé! Biết đâu bạn sẽ "mê mẩn" một câu hỏi nào đó? ☺️</p> : <p>Hãy thử lại với một từ khóa khác nhé! 😇</p>}
                            
                        </div>
                    </div>
                )
            }
            {questions.length > 0 && (
                <div className={cx('content-wrapper')}>
                    <div>
                        <label className={cx('cell-label')}>
                            Câu hỏi - ID {questions[index].id}{' '}
                            {questions[index].image && (
                                <Popup
                                    modal
                                    trigger={
                                        <button className={cx('media-btn')}>
                                            <FontAwesomeIcon icon={faImage} />
                                        </button>
                                    }
                                >
                                    <div className={cx('explain-wrapper')}>
                                        <p>Ảnh</p>
                                        <img className={cx('question-image')} src={questions[index].image} alt="" />
                                    </div>
                                </Popup>
                            )}
                            {questions[index].audio && (
                                <Popup
                                    modal
                                    trigger={
                                        <button className={cx('media-btn')}>
                                            <FontAwesomeIcon icon={faBullhorn} />
                                        </button>
                                    }
                                >
                                    <div className={cx('explain-wrapper', 'audio-wrapper')}>
                                        <p>Âm thanh</p>
                                        <CustomizeAudio src={questions[index].audio} autoPlay={false} />
                                    </div>
                                </Popup>
                            )}
                        </label>
                        <p className={cx('cell-input')}>{questions[index].question}</p>
                    </div>
                    <div>
                        <label className={cx('cell-label')}>
                            Câu trả lời{' '}
                            {questions[index].explanation && (
                                <Popup
                                    modal
                                    trigger={
                                        <button>
                                            <FontAwesomeIcon icon={faCircleInfo} className={cx('explain-btn')} />
                                        </button>
                                    }
                                >
                                    <div className={cx('explain-wrapper')}>
                                        <p>Giải thích</p>
                                        <p>{questions[index].explanation}</p>
                                    </div>
                                </Popup>
                            )}
                        </label>
                        <p className={cx('cell-input')}>{
                        questions[index].answer.split("~|")[0].replaceAll("~+", ", ")
                        }</p>
                        <button className={cx('remove-favourite')} onClick={() => deleteFavouriteQues(index)}>
                            <FontAwesomeIcon icon={faHeartCrack} />  Loại khỏi danh sách ưa thích
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default LovedQuestion;
